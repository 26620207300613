/* All Variables defined for mobile-specificity  */
:root {
    /* ~~~~~~~~~~~~~~~~~~MODERN~~~~~~~~~~~~~~~~~ */
    /**
        The definitions of the variables below are all implementations of the newly standardized Visual Expressions project,
        there are a handful of repeats which match the variables below in Legacy; they exist to provide coverage for both
        new naming and old naming. NOTE: ALL NEW DEVELOPMENT POST VISUAL EXPRESSIONS LAUNCH SHOULD USE THE VARIABLES BELOW.
     */
    /*                  COLORS                  */
    --dark: #020215;
    --gold: #aa8247;
    --gold-dark-300: #775b32;
    --passion-pink: #ea0063;
    --passion-pink-dark-200: #bb004f;
    --passion-pink-light-100: #fde6ef;
    --princess-blue: #003595;
    --princess-blue-dark-200: #002a77;
    --princess-blue-light-100: #e6ebf4;
    --princess-blue-light-200: #ccd7ea;
    --princess-blue-light-400: #99aed5;
    --princess-blue-light-600: #6786be;
    --princess-blue-light-700: #4d72b5;
    --princess-blue-light-800: #335daa;
    --sand-light-500: #f9f7f6;
    --sand-light-700: #f6f4f2;
    --sea-foam: #cce7eb;
    --steel-grey: #6c6c78;
    /* Transparency */
    --dark-15: #02021526;
    --dark-30: #0202154d;
    --white-15: #ffffff26;
    --white-30: #ffffff4d;
    --white-40: #ffffff66;
    --white-5: #ffffff0d;
    --white-50: #ffffff80;
    --white-60: #ffffff99;
    --white-80: #ffffffcc;
    --sand-dark-500: #797877;
    --sand-dark-600: #61605f;
    --sand-dark-700: #494847;
    --sand-dark-800: #30302f;
    --sand-dark-900: #181818;
    --sand-light-100: #ffffff;
    --sand-light-200: #fcfcfb;
    --sand-light-300: #fbfafa;
    --sand-light-400: #faf9f8;
    --sand-light-500: #f9f7f6;
    --sand-light-600: #f7f5f4;
    --sand-light-700: #f6f4f2;
    --sand-light-800: #f5f2f1;
    --sand-light-900: #f3f1ef;
    --sea-foam: #cce7eb;
    --sea-foam-dark-100: #b8d0d4;
    --sea-foam-dark-200: #a3b9bc;
    --sea-foam-dark-300: #8fa2a5;
    --sea-foam-dark-400: #7a8b8d;
    --sea-foam-dark-500: #667476;
    --sea-foam-dark-600: #525c5e;
    --sea-foam-dark-700: #3d4546;
    --sea-foam-dark-800: #292e2f;
    --sea-foam-dark-900: #141717;
    --sea-foam-light-100: #fafdfd;
    --sea-foam-light-200: #f5fafb;
    --sea-foam-light-300: #f0f8f9;
    --sea-foam-light-400: #ebf5f7;
    --sea-foam-light-500: #e6f3f5;
    --sea-foam-light-600: #e0f1f3;
    --sea-foam-light-700: #dbeef1;
    --sea-foam-light-800: #d6ecef;
    --sea-foam-light-900: #d1e9ed;
    --steel-grey: #6c6c78;
    --steel-grey-dark-100: #61616c;
    --steel-grey-dark-200: #565660;
    --steel-grey-dark-300: #4c4c54;
    --steel-grey-dark-400: #414148;
    --steel-grey-dark-500: #36363c;
    --steel-grey-dark-600: #2b2b30;
    --steel-grey-dark-700: #202024;
    --steel-grey-dark-800: #161618;
    --steel-grey-dark-900: #0b0b0c;
    --steel-grey-light-100: #f0f0f2;
    --steel-grey-light-200: #e2e2e4;
    --steel-grey-light-300: #d3d3d7;
    --steel-grey-light-400: #c4c4c9;
    --steel-grey-light-500: #b6b6bc;
    --steel-grey-light-600: #a7a7ae;
    --steel-grey-light-700: #9898a1;
    --steel-grey-light-800: #898993;
    --steel-grey-light-900: #7b7b86;
    --white: #ffffff;

    /* TOKENIZED COLORS  */
    --background-accent-blue: var(--princess-blue);
    --background-accent-blue-subtle: var(--princess-blue-light-100);
    --background-accent-dark: var(--dark);
    --background-accent-pink: var(--passion-pink);
    --background-accent-sea-foam: var(--sea-foam);
    --background-accent-sea-foam-subtle: var(--sea-foam-light-300);
    --background-button-blue-version-fab-default: var(--white);
    --background-button-blue-version-image-default: var(--white);
    --background-button-blue-version-primary-default: var(--primary-cta);
    --background-button-blue-version-primary-disabled: var(--steel-grey-light-300);
    --background-button-blue-version-primary-hover: var(--primary-cta-hover);
    --background-button-blue-version-primary-inverted-default: var(--white);
    --background-button-blue-version-primary-inverted-disabled: var(--steel-grey-light-300);
    --background-button-blue-version-primary-inverted-hover: var(--princess-blue-light-200);
    --background-button-blue-version-tertiary-default: var(--princess-blue);
    --background-button-blue-version-tertiary-disabled: var(--steel-grey-light-300);
    --background-button-blue-version-tertiary-hover: var(--princess-blue-dark-200);
    --background-button-blue-version-tertiary-inverted-default: var(--gold);
    --background-button-blue-version-tertiary-inverted-disabled: var(--steel-grey-light-300);
    --background-button-blue-version-tertiary-inverted-hover: var(--gold-dark-300);
    --background-button-pink-version-fab-default: var(--white);
    --background-button-pink-version-image-default: var(--white);
    --background-button-pink-version-primary-default: var(--passion-pink);
    --background-button-pink-version-primary-disabled: var(--steel-grey-light-300);
    --background-button-pink-version-primary-hover: var(--passion-pink-dark-200);
    --background-button-pink-version-primary-inverted-default: var(--white);
    --background-button-pink-version-primary-inverted-disabled: var(--steel-grey-light-300);
    --background-button-pink-version-primary-inverted-hover: var(--passion-pink-light-100);
    --background-button-pink-version-tertiary-default: var(--passion-pink);
    --background-button-pink-version-tertiary-disabled: var(--steel-grey-light-300);
    --background-button-pink-version-tertiary-hover: var(--passion-pink-dark-200);
    --background-button-pink-version-tertiary-inverted-default: var(--passion-pink);
    --background-button-pink-version-tertiary-inverted-disabled: var(--steel-grey-light-300);
    --background-control-toggle-active: var(--princess-blue);
    --background-control-toggle-disabled: var(--dark-15);
    --background-control-toggle-inverted-active: var(--white);
    --background-control-toggle-inverted-disabled: var(--white-30);
    --background-input-default: var(--white);
    --background-input-disabled: var(--steel-grey-light-200);
    --background-input-inverted-default: var(--white-5);
    --background-input-inverted-disabled: var(--white-15);
    --background-label-blue: var(--princess-blue);
    --background-label-pink: var(--passion-pink);
    --background-label-sea-foam: var(--sea-foam);
    --background-label-white: var(--white);
    --background-surface-primary: var(--sand-light-500);
    --background-surface-secondary: var(--white);
    --border-base-divider: var(--steel-grey-light-300);
    --border-button-blue-version-image-default: var(--dark);
    --border-button-blue-version-secondary-default: var(--princess-blue);
    --border-button-blue-version-secondary-disabled: var(--steel-grey-light-300);
    --border-button-blue-version-secondary-hover: var(--princess-blue-dark-200);
    --border-button-blue-version-secondary-inverted-default: var(--white);
    --border-button-blue-version-secondary-inverted-disabled: var(--steel-grey-light-300);
    --border-button-pink-version-image-default: var(--dark);
    --border-button-pink-version-secondary-default: var(--passion-pink);
    --border-button-pink-version-secondary-disabled: var(--steel-grey-light-300);
    --border-button-pink-version-secondary-hover: var(--passion-pink-dark-200);
    --border-button-pink-version-secondary-inverted-default: var(--white);
    --border-button-pink-version-secondary-inverted-disabled: var(--steel-grey-light-300);
    --border-control-tab-active: var(--passion-pink);
    --border-control-tab-default: var(--steel-grey-light-800);
    --border-control-toggle-default: var(--dark);
    --border-control-toggle-hover: var(--steel-grey);
    --border-control-toggle-inverted-default: var(--white);
    --border-control-toggle-inverted-hover: var(--sand-light-700);
    --border-focus-default: var(--dark);
    --border-focus-default-inverted: var(--white);
    --border-input-active: var(--princess-blue);
    --border-input-default: var(--steel-grey-light-800);
    --border-input-disabled: var(--steel-grey-light-800);
    --border-input-hover: var(--steel-grey-dark-300);
    --border-input-inverted-active: var(--white);
    --border-input-inverted-default: var(--white-40);
    --border-input-inverted-disabled: var(--white-30);
    --border-input-inverted-hover: var(--white-80);
    --icon-accent-blue: var(--princess-blue);
    --icon-accent-pink: var(--passion-pink);
    --icon-base-primary: var(--dark);
    --icon-base-primary-inverted: var(--white);
    --icon-base-secondary: var(--steel-grey);
    --icon-base-secondary-inverted: var(--white-60);
    --icon-interactive-blue-default: var(--princess-blue);
    --icon-interactive-blue-disabled: var(--steel-grey-light-300);
    --icon-interactive-blue-hover: var(--princess-blue-dark-200);
    --icon-interactive-gold-default: var(--gold);
    --icon-interactive-gold-disabled: var(--steel-grey-light-300);
    --icon-interactive-gold-hover: var(--gold-dark-300);
    --icon-interactive-link-default: var(--dark);
    --icon-interactive-pink-default: var(--passion-pink);
    --icon-interactive-pink-disabled: var(--steel-grey-light-300);
    --icon-interactive-pink-hover: var(--passion-pink-dark-200);
    --icon-interactive-primary-default: var(--steel-grey);
    --icon-interactive-primary-hover: var(--dark);
    --icon-interactive-primary-inverted-default: var(--steel-grey-light-300);
    --icon-interactive-primary-inverted-hover: var(--white);
    --icon-interactive-secondary-default: var(--dark);
    --icon-interactive-secondary-hover: var(--steel-grey);
    --icon-interactive-secondary-inverted-default: var(--white);
    --icon-interactive-secondary-inverted-hover: var(--steel-grey-light-300);
    --icon-interactive-toggle-active: var(--white);
    --icon-interactive-toggle-disabled: var(--dark-30);
    --icon-interactive-toggle-inverted-active: var(--princess-blue);
    --icon-interactive-toggle-inverted-disabled: var(--white-50);
    --text-accent-blue: var(--princess-blue);
    --text-accent-pink: var(--passion-pink);
    --text-base-primary: var(--dark);
    --text-base-primary-inverted: var(--white);
    --text-base-secondary: var(--steel-grey);
    --text-button-blue-version-fab-default: var(--dark);
    --text-button-blue-version-image-default: var(--dark);
    --text-button-blue-version-link-default: var(--dark);
    --text-button-blue-version-link-disabled: var(--steel-grey-light-900);
    --text-button-blue-version-primary-default: var(--white);
    --text-button-blue-version-primary-disabled: var(--steel-grey-light-900);
    --text-button-blue-version-primary-inverted-default: var(--princess-blue);
    --text-button-blue-version-primary-inverted-disabled: var(--steel-grey-light-900);
    --text-button-blue-version-primary-inverted-hover: var(--princess-blue-dark-200);
    --text-button-blue-version-secondary-default: var(--dark);
    --text-button-blue-version-secondary-disabled: var(--steel-grey-light-900);
    --text-button-blue-version-secondary-inverted-default: var(--white);
    --text-button-blue-version-secondary-inverted-disabled: var(--steel-grey-light-300);
    --text-button-blue-version-tertiary-default: var(--dark);
    --text-button-blue-version-tertiary-inverted-default: var(--white);
    --text-button-pink-version-fab-default: var(--dark);
    --text-button-pink-version-image-default: var(--dark);
    --text-button-pink-version-link-default: var(--dark);
    --text-button-pink-version-link-disabled: var(--steel-grey-light-900);
    --text-button-pink-version-primary-default: var(--white);
    --text-button-pink-version-primary-disabled: var(--steel-grey-light-900);
    --text-button-pink-version-primary-inverted-default: var(--passion-pink);
    --text-button-pink-version-primary-inverted-disabled: var(--steel-grey-light-900);
    --text-button-pink-version-primary-inverted-hover: var(--passion-pink-dark-200);
    --text-button-pink-version-secondary-default: var(--dark);
    --text-button-pink-version-secondary-disabled: var(--steel-grey-light-900);
    --text-button-pink-version-secondary-inverted-default: var(--white);
    --text-button-pink-version-secondary-inverted-disabled: var(--steel-grey-light-300);
    --text-button-pink-version-tertiary-default: var(--dark);
    --text-button-pink-version-tertiary-inverted-default: var(--white);
    --text-label-blue: var(--princess-blue);
    --text-label-pink: var(--passion-pink);
    --text-label-white: var(--white);


    /*             TYPOGRAPHY SIZING TOKENS                */
    --size-additional-styles-tag: 16px;
    --size-additional-styles-eyebrow: 20px;
    --size-additional-styles-quote: 32px;
    --size-additional-styles-xl: 180px;
    --size-body-copy-body-copy-small: 14px;
    --size-body-copy-body-copy-medium: 18px;
    --size-body-copy-body-copy-large: 22px;
    --size-body-copy-body-copy-x-large: 25px;
    --size-breadcrumbs-current-page: 14px;
    --size-breadcrumbs-previous-page: 14px;
    --size-call-to-action-cta-button-text-link: 16px;
    --size-call-to-action-cta-button-text-small: 16px;
    --size-call-to-action-tertiary-cta-button-text: 18px;
    --size-call-to-action-cta-button-text-large: 20px;
    --size-call-to-action-cta-button-text-medium: 16px;
    --size-display-headline-xxsmall: 25px;
    --size-display-headline-xsmall: 32px;
    --size-display-headline-small: 44px;
    --size-display-headline-medium: 70px;
    --size-display-headline-large: 88px;
    --size-form-component-styles-form-paragraph : 16px;
    --size-form-component-styles-form-list-item : 16px;
    --size-form-component-styles-form-legend : 16px;
    --size-form-component-styles-form-advisory: 14px;
    --size-form-component-styles-form-label: 16px;
    --size-form-component-styles-form-value: 16px;
    --size-section-title-desktop-title-example: 22px;
    --size-subtitles-sub-headline-small: 18px;
    --size-subtitles-sub-headline-medium: 22px;
    --size-subtitles-sub-headline-large: 24px;
    --size-caption: 18px;
    --size-cp-footer-nav-li: 16px;
    --size-copyright: 16px;
    --size-cp-footer:16px;
    

    /*                     TYPOGRAPHY FONT FAMILIES                   */
    /* Used for most everything content; body and large texts, etc.*/
    --proxima-nova-adobe: 'proxima-nova', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova: 'Proxima Nova', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-it: 'Proxima Nova Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-thin: 'Proxima Nova Thin', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-thin-it: 'Proxima Nova Thin Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-light: 'Proxima Nova Light', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-light-it: 'Proxima Nova Light Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-medium: 'Proxima Nova Medium', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-medium-it: 'Proxima Nova Medium Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-semi-bold: 'Proxima Nova Semibold', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-semi-bold-it: 'Proxima Nova Semibold Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-bold: 'Proxima Nova Bold', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-bold-it: 'Proxima Nova Bold Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-black: 'Proxima Nova Black', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-black-it: 'Proxima Nova Black Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-extra-bold: 'Proxima Nova Extrabold', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --proxima-nova-extra-bold-it: 'Proxima Nova Extrabold Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    /* Used for large fonts, mostly headers. */
    --domaine-display-regular: 'Domaine Display Regular', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --domaine-display-medium: 'Domaine Display Medium', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --domaine-display-bold: 'Domaine Display Bold', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;

    /* Font Weight Variables for adobe fonts */
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;

    /* Font Style Variables for adobe fonts */
    --font-style-normal: normal;
    --font-style-italic: italic;

    /* Adobe fonts - Primary font family stack for proxima-nova */
    --proxima-nova: var(--proxima-nova-adobe);
    --proxima-nova-it: var(--proxima-nova-adobe);
    --proxima-nova-medium: var(--proxima-nova-adobe);
    --proxima-nova-medium-it: var(--proxima-nova-adobe);
    --proxima-nova-semi-bold: var(--proxima-nova-adobe);
    --proxima-nova-semi-bold-it: var(--proxima-nova-adobe);
    --proxima-nova-bold: var(--proxima-nova-adobe);
    --proxima-nova-bold-it: var(--proxima-nova-adobe);


    /* ~~~~~~~~~~~~~~~~~~LEGACY~~~~~~~~~~~~~~~~~~~~~ */
    /**
        The odd mapping for all of the variables below is to handle a wide range of styling cases which span across all of princess.com (non-aem)
        which did not implement the new naming and variable conventions as above.
     */
    /* Source Sans 3 is used as a backup to support certain superscript tags that are unsupported by our primary fonts.*/
    --red-hat-text: var(--proxima-nova);
    --red-hat-text-it: var(--proxima-nova-it);
    --red-hat-text-bold: var(--proxima-nova-bold);
    --red-hat-text-bold-it: var(--proxima-nova-bold-it);
    --red-hat-text-medium: var(--proxima-nova-medium);
    --red-hat-text-medium-it: var(--proxima-nova-medium-it);
    /* Other mappings as needed */
    --gotham-xn-book: var(--proxima-nova);
    --gotham-xn-medium: var(--proxima-nova-medium);
    --gotham-book: var(--proxima-nova);
    --gotham-book-it: var(--proxima-nova-it);
    --gotham-bold: var(--proxima-nova-bold);
    --gotham-bold-it: var(--proxima-nova-bold-it);
    --roboto-condensed: var(--proxima-nova);
    --roboto-condensed-bold: var(--proxima-nova-bold);
    /* Lora - Headers */
    --lora-medium-it: var(--domaine-display-regular);
    --lora-bold-it: var(--domaine-display-bold);
    --default-sans-serif: 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;

    /*
     * Font scaling based on a base unit of 14px
     */
    --font-size-m3:  0.6875em;  /*11px*/
    --font-size-m2:  0.75em;    /*12px*/
    --font-size-m1:  0.8125em;  /*13px*/
    --font-size-0:   0.875em;   /*14px*/
    --font-size-p0:   0.875em;   /*14px*/
    --font-size-p1:  0.9375em;  /*15px*/
    --font-size-p2:  1em;   /*16px*/
    --font-size-p3:  1.0625em;   /*17px*/
    --font-size-p4:  1.125em;   /*18px*/
    --font-size-p5:  1.1875em;   /*19px*/
    --font-size-p6:  1.25em;   /*20px*/
    --font-size-p7:  1.3125em;  /*21px*/
    --font-size-p8:  1.375em;   /*22px*/
    --font-size-p9:  1.4375em;   /*23px*/
    --font-size-p10: 1.5em;     /*24px*/
    --font-size-p11: 1.5625em;   /*25px*/
    --font-size-p12: 1.625em;   /*26px*/
    --font-size-p13: 1.6875em;   /*27px*/
    --font-size-p14: 1.75em;     /*28px*/
    --font-size-p15: 1.8125em;   /*29px*/
    --font-size-p16: 1.875em;   /*30px*/
    --font-size-p17: 1.9375em;   /*31px*/
    --font-size-p18: 2em;   /*32px*/
    --font-size-p19: 2.0625em;   /*33px*/
    --font-size-p20: 2.125em;   /*34px*/
    --font-size-p21: 2.1875em;                 /*35px*/
    --font-size-p22: 2.25em;   /*36px*/
    --font-size-p23: 2.3125em;   /*37px*/
    /*
    * Branded colors
    */
    --primary-blue: #003595;                /** updated - name from primary and color */
    --primary-blue-dark: #002568;           /** updated - name from princess-blue-alt and color */
    --primary-dark: #020215;                /** updated - name from neutral-grey-dark and color */
    --primary-medium: #6C6C78;              /** new - name from neutral-grey-dark and color */
    --primary-light: #F2EFED;               /** updated - name from neutral-warm-light and color */
    --primary-extra-light: #F8F6F2;         /** added - name from neutral-warm-light and color */

    /*
     * Colors to communicate state
     */
    --primary-cta: linear-gradient(180deg, #1A5AD7, #003595);
    --primary-cta-hover: linear-gradient(180deg, #1A5AD7, #002568);
    --secondary-cta: linear-gradient(180deg, #ffffff, #f1f1f1);
    --secondary-cta-hover: linear-gradient(180deg, #ffffff, #f9f9f9);
    --success: #007a7c;                     /** added */
    --success-dark: #035c67;                /** added */
    --caution: #d4673c;                     /** added */
    --caution-dark: #b54d24;                /** added */
    --error: #b92d2d;                       /** added */
    --error-dark: #9a2626;                  /** added */
    --error-bg: #fdfce3;                    /** updated - name from bg-error */
    --inactive: rgba(255, 255, 255, .7);   /** added - name from bg-error */

    /*
     * Colors to communicate loyality level status
     */
    --loyalty-blue: #003595;           /** updated */
    --loyalty-gold: #cc9f53;           /** updated */
    --loyalty-ruby: #8e1537;           /** updated */
    --loyalty-platinum: #7b868c;       /** updated */
    --loyalty-elite: #000000;          /** updated */

    /*
     * Accent Treatments
     */
    --accent-backdrop: #787c86;             /** added */
    --accent-form: #e5f3fd;                 /* updated - name from bg-form */
    --accent-gold: #cc9f53;                 /** added */
    --accent-gold-dark:#aa8247;             /** added */
    --accent-silver: #cdd1d5;               /** added */
    --accent-pink: #EA0063;                 /** added */
    --accent-pink-dark: #D7005B;

    /*
     * Transparency treatments
     */
    --transparent-dark: rgba(2, 2, 21, .7);                     /* added */
    --transparent-medium: rgba(2, 2, 21, .5);              /* added */
    --transparent-light: rgba(255, 255, 255, .7);               /* added */
    --transparent-glow: radial-gradient(50% 71.67% at 50% 100%, rgba(195, 186, 180, 0.4) 0%, rgba(242, 239, 237, 0.2) 89.06%);          /** added */
    --transparent-wave: radial-gradient(128.8% 146.38% at 8.67% 69.32%, rgba(195, 186, 180, 0.16) 0%, rgba(242, 239, 237, 0.4) 100%);   /** added */
    --transparent-glass: linear-gradient(333.35deg, rgba(255, 255, 255, 0.3) 31.34%, rgba(255, 255, 255, 0.2) 75.44%);                  /** added */

    /*
     * Auxiliary colors to fill gaps in old colors that are not available in new brand colors
     */
    --auxiliary-blue-dark: #09213d;     /** updated - name from bg-extra-dark and color */
    --auxiliary-blue: #1A5AD7;     /** updated - name from bg-extra-dark and color */
    --auxiliary-blue-light: #e5f3fd;    /** updated - name from bg-light and color */
    --auxiliary-blue-extra-light: #f7fbfe;    /** updated - name from bg-light and color */
    --auxiliary-green: #6d9d5b;                   /** updated - color */
    --auxiliary-green-light: #EBF3F3;
    /*--auxiliary-green-dark: #035c67;              /** updated - name from secondary-dark and color */
    --auxiliary-red: #af3636;                        /** added */
    --auxiliary-red-dark: #912d2d;                   /** added */
    --auxiliary-orange: #FCB215;                   /** added */
    --auxiliary-secondary-blue: #009dd2;

    /*
     * Neutrals colors for backgrounds, borders, and text treatments
     */
    --neutral-black: #000;                  /* keep */
    --neutral-white: #fff;                  /* keep */

    --neutral-dark: #333537;                /* keep */
    --neutral-medium: #cccccc;              /* updated - name from neutral-grey and color */
    --neutral-light: #e2e3e4;               /* updated - name from neutral-header-bg and color */

    --neutral-cool-dark: #54575a;           /* keep */
    --neutral-cool-medium: #939598;         /* updated - name from neutral-cool-medium and color */
    --neutral-cool-light: #f1f1f1;          /* keep */
    --neutral-cool-extra-light: #f9f9f9;    /* keep */

    --neutral-warm-medium: #eae5dc;          /* updated - color */
    --neutral-warm-light: #f3efee;           /* updated - color */

    /*
     * Removed variable names

    --ocean-blue: #003087;                    removing
    --font-blue: #0054A0;                     removing
    --primary: #6d9d5b;                       removing
    --primary-dark: #587f49;                  removing
    --primary-alt: #4a6b3e;                   removing
    -secondary: #cc9f53;                      removing - color
    -secondary-dark: #b18234;                 removing - name from secondary-alt and color
    -primary-extra-dark: #09213d;          removing - name from princess-blue-alt and color
    -primary-extra-light: #f7fbfe;         removing - name from bg-form
    --bg-extra-dark: #09213d;              removing
    --bg-dark: #0d2f56;                    removing
    --bg-form: #e5f3fd;                    removing
    --bg-light: #f7fbfe;                   removing
    --bg-light-alt: #ebf3f3;               removing


    /*
     * Design Treatments
     */
    --border-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    --top-box-shadow: 0px -4px 9px -3px #00000033;
    --btm-box-shadow: 0px 5px 6px #0000004D;
}
    

/* TODO: define mobile breakpoint. */
@media (max-width: 600px) {
    :root {
        --size-additional-styles-eyebrow: 16px;
        --size-additional-styles-tag: 16px;
        --size-additional-styles-quote: 22px;
        --size-additional-styles-xl: 70px;
        --size-body-copy-body-copy-small: 14px;
        --size-body-copy-body-copy-medium: 16px;
        --size-body-copy-body-copy-large: 18px;
        --size-body-copy-body-copy-x-large: 20px;
        --size-breadcrumbs-current-page: 14px;
        --size-breadcrumbs-previous-page: 14px;
        --size-call-to-action-cta-button-text-link: 16px;
        --size-call-to-action-cta-button-text-small: 16px;
        --size-call-to-action-tertiary-cta-button-text: 16px;
        --size-call-to-action-cta-button-text-large: 20px;
        --size-call-to-action-cta-button-text-medium: 16px;
        --size-display-headline-xxsmall: 22px;
        --size-display-headline-xsmall: 24px;
        --size-display-headline-small: 32px;
        --size-display-headline-medium: 40px;
        --size-display-headline-large: 56px;
        --size-form-component-styles-form-advisory: 14px;
        --size-form-component-styles-form-label: 14px;
        --size-form-component-styles-form-value: 14px;
        --size-section-title-desktop-title-example: 19px;
        --size-subtitles-sub-headline-small: 16px;
        --size-subtitles-sub-headline-medium: 18px;
        --size-subtitles-sub-headline-large: 20px;
        --size-form-component-styles-form-legend : 14px;
        --size-copyright: 14px;
        --size-caption: 16px;
        --font-size-p0:14px;
        --font-size-p2: 14px; /*16 -> 14px*/ 
        --font-size-p4: 16px; /*18 -> 16px*/ 
        --font-size-p6: 16px; /*20 -> 16px*/ 
        --font-size-p8: 18px; /*22 -> 18px*/ 
        --font-size-p10: 20px; /*24 -> 20px*/ 
        --font-size-p11: 22px; /*25 -> 22px*/ 
        --font-size-p18: 24px; /*32 -> 24px*/    
    }
}