/* common styles to all tabs */
.full-tab, .full-tab li, .folder-tab, .folder-tab li, .compact-tab,
	.compact-tab li {
	height: auto;
	margin: 0;
	padding: 0;
}

.full-tab, .folder-tab, .compact-tab {
	pointer-events: auto;
	list-style-type: none;
	clear: left;
	position: relative;
	transition: none 0s ease 0s;
	z-index: 95;
}

.full-tab li, .folder-tab li, .compact-tab li {
	display: inline-block;
	font-weight: 400;
	position: relative;
	text-align: center;
}

.full-tab li+li, .folder-tab li+li, .compact-tab li+li {
	margin-left: 3px;
}

.full-tab a, .folder-tab a, .compact-tab a {
	display: block;
	font-weight: 400;
	outline: none;
	text-decoration: none;
}

.full-tab, .compact-tab, .droptab-selector {
	font-family:  var(--red-hat-text);
	font-weight: var(--font-weight-regular);
    font-style: var(--font-style-normal);
	letter-spacing: 1px;
}

/* full-tab styles */
.full-tab, .compact-tab {
	margin-bottom: 3px;
	overflow: visible;
}

@media screen and (min-width: 960px) {
	.full-width .full-tab, .full-width .compact-tab {
		text-align: center;
	}
}

.full-tab li, .compact-tab li {
	border: none;
	border-radius: 1px;
}

.full-tab-alt li {
	-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .25);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, .25);
}

.full-tab a,
.compact-tab a {
	color: #00779B;
	font-size: 1.125em; /* 18px */
	line-height: 18px;
	padding: 8px 18px;
	background: #FFFFFF;
	border: 2px solid #e6e6e6;
}

.full-tab a:hover,
.full-tab a:focus,
.compact-tab a:hover,
.compact-tab a:focus {
	background: #e5f3fd;
	border: 2px solid #e5f3fd;
}

.full-tab .active a,
.compact-tab .active a {
	color: #FFFFFF;
	background: #00779B;
	border-color: #00779B;
}

.full-tab .active a:after,
.compact-tab .active a:after {
	content: '';
	width: 0;
	height: 0;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-top: 13px solid #00779B;
	position: absolute;
	bottom: -12px;
	left: 50%;
	margin-left: -12px;
}

.droptab-mobile-menu.full-tab a,
.droptab-mobile-menu.compact-tab a {
	background: none;
}

.full-tab .active a:focus,
.compact-tab .active a:focus,
.full-tab .active a:hover,
.compact-tab .active a:hover {
	color: #00779B;
	background: #e5f3fd;
	border-color: #e5f3fd;
}

.full-tab .active a:focus:after,
.compact-tab .active a:focus:after,
.full-tab .active a:hover:after,
.compact-tab .active a:hover:after {
	border-top-color: #e5f3fd;
}

.droptab-mobile-menu.full-tab .active a:after,
.droptab-mobile-menu.compact-tab .active a:after {
	display: none;
}



/* folder-tab styles */   
.folder-tab {
	overflow: hidden;
	margin-bottom: -1px;
}

.folder-tab li {
	border-radius: 6px 6px 0 0;
	box-shadow: none;
	font-size: 0.938em; /* 15px */
	padding: 0;
}

.folder-tab li:hover {
	box-shadow: none;
}

.folder-tab a, .folder-tab a:link, .folder-tab a:visited, .folder-tab a:active
	{
	color: #FFFFFF;
	line-height: 15px;
	padding: 8px 12px;
}

.folder-tab li.active {
	background: #FFF;
	border-bottom: 1px solid #FFFFFF;
	border-left: 1px solid #b2b2b2;
	border-top: 1px solid #b2b2b2;
	border-right: 1px solid #b2b2b2;
	box-shadow: 3px 0 1px -1px #ccc;
}

.folder-tab li.active a, .folder-tab li.active a:link, .folder-tab li.active a:visited,
	.folder-tab li.active a:active {
	color: #000;
}

.folder-tab-content {
	background: #FFFFFF;
	position: relative;
	z-index: 1;
}

/* compact-tab styles */
.compact-tab a {
	padding: 4px 15px;
}

.compact-tab span {
	display: block;
	font-size: 0.813em; /* 13px */
}

.compact-tab.droptab-mobile-menu span {
	display: inline;
	font-size: 	1.125em; /* 18px */
}

/* common mobile styles */
.droptab-menu.droptab-mobile-menu {
	margin-bottom: 15px;
}

.droptab-menu.droptab-mobile-menu li {
	border: none;
}

.droptab-menu.droptab-mobile-menu li, .droptab-menu.droptab-mobile-menu li.active
	{
	background: none repeat scroll 0 0 #0d2f56;
	-webkit-border-radius: 0;
	border-radius: 0;
	display: block;
	height: 50px;
	padding: 0 15px;
	text-align: left;
}

.droptab-menu.droptab-mobile-menu li.active:after {
	border: none;
}

.droptab-menu.droptab-mobile-menu li:last-child {
	border-bottom: 10px solid #00779B;
	height: 60px;
}

.droptab-menu.droptab-mobile-menu li+li {
	border-top: 1px solid rgba(255, 255, 255, 0.25);
	margin: 0;
}

.droptab-menu.droptab-mobile-menu li.active a, .droptab-menu.droptab-mobile-menu a
	{
	color: #FFFFFF;
}

.droptab-menu.droptab-mobile-menu a {
	font-size: 	1.125em; /* 18px */
	line-height: 50px;
	padding: 0;
}

/* Global styles */
.droptab-select-current, .droptab-select-btn, .droptab-select-btn:before,
	.menu2select, .menu3select {
	-webkit-transition: all 250ms ease;
	-moz-transition: all 250ms ease;
	-o-transition: all 250ms ease;
	transition: all 250ms ease;
}
.droptab-mobile-menu {
	border-bottom: none;
	height: 0;
	margin: 0;
	overflow: hidden;
	text-align: left;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

.droptab-mobile-menu.open {
	height: auto;
}

.droptab-selector {
	background: none repeat scroll 0 0 #00779B;
	color: #fff;
	cursor: pointer;
	display: block;
	font-size: 1.38462em;
}

.droptab-selector.on {
	display: block;
}

.droptab-selector.open {
	
}

.droptab-select-current {
	line-height: 52px;
	opacity: 1;
	padding-left: 15px;
}

.droptab-selector, .droptab-select-btn {
	min-height: 52px;
}

.droptab-select-btn {
	border-left: 1px solid #80BDD1;
	float: right;
	font-size: 0.688em; /* 11px */
	height: 100%;
	line-height: 20px;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	width: 50px;
}

.droptab-select-btn:before {
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #FFFFFF;
	content: "";
	display: block;
	height: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 15px;
	width: 0;
}

.droptab-selector.open .droptab-select-btn {
	background: #409CBA;
}

.droptab-selector.open .droptab-select-btn:before {
	-moz-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.drop-tab-hidden {
	opacity: 0;
}

.hide-text {
	text-indent: -999em;
}

textarea.code {
	width: 100%;
}